

























































import Vue from 'vue';
import Component from 'vue-class-component';

import { namespace } from 'vuex-class';
import { ResetPassword } from '../api/auth/reset-password.class';
import Toast from '../shared/types/toast.class';
import { ToastType } from '../shared/types/toast-type.enum';
import { LoginResult } from '../api/auth/login-result.class';
import { Login } from '../api/auth/login.class';

const authModule = namespace('auth');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({})
export default class ExecutePasswordReset extends Vue {
  confirmation = '';
  reset: ResetPassword = new ResetPassword();

  @appModule.Getter('isLoading')
  isLoading!: (id: string) => boolean;

  @appModule.Mutation('addLoader')
  addLoader!: (id: string) => void;

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @appModule.Mutation('removeLoader')
  removeLoader!: (id: string) => void;

  @authModule.Action('resetPassword')
  resetPassword!: (payload: ResetPassword) => Promise<void>;

  @authModule.Action('login')
  login!: (login: Login) => Promise<LoginResult>;

  async submit() {
    this.addLoader('passwordreset');
    this.reset.token = this.$route.params.token;
    const toast = new Toast('Your password has been reset!', ToastType.SUCCESS);
    try {
      await this.resetPassword(this.reset);
      this.addToast(toast);
      this.addToast(new Toast('Returning to login'));
      setTimeout(() => {
        this.$router.push({ path: '/login' });
      }, 1000);
    } catch (e) {
      toast.message = this.$t(e.message) as string;
      toast.type = ToastType.ERROR;
      this.addToast(toast);
    }
    this.removeLoader('passwordreset');
  }
}
